import React, { useEffect } from 'react'
import LazyLoad from 'vanilla-lazyload'
import classNames from 'classnames'
import objectFitImages from 'object-fit-images'

import styles from './responsive-image.module.scss'

const ResponsiveImage = ({
  src,
  srcset,
  srcsetWebP,
  sizes,
  placeholder,
  alt,
  outerClass,
  outerStyle,
  imgClass,
  imgStyle,
  aspectRatio,
  lazyLoad,
  objectFit,
  objectPosition,
  fillContainer,
}: Basiis.ResponsiveImage & Basiis.ResponsiveImageDisplay) => {
  const lazyLoadClass = lazyLoad ? 'lazy' : ''
  const isClient = typeof document === 'object'

  // only initialize lazy loading one time for the app
  if (isClient && !document.lazyLoadInstance && lazyLoad) {
    document.lazyLoadInstance = new LazyLoad({
      'elements_selector': '.lazy',
      'class_loaded': 'loaded',
      'callback_loaded': el => {
        el.parentElement && el.parentElement.setAttribute('data-loaded', 'true')
      },
    })

    objectFitImages(null)
  }

  // update lazyLoad after first rendering of every image
  useEffect(() => {
    if (isClient && lazyLoad) {
      document.lazyLoadInstance.update()
    }
  }, [])

  const objectFitPolyfill = {
    fontFamily: `'object-fit: ${objectFit}; object-position: ${objectPosition}'`,
  }

  const dataSrcAttr: {
    'src'?: string,
    'data-src'?: string
    'data-srcset'?: string
  } = {}

  if (lazyLoad) {
    dataSrcAttr['data-src'] = src
    if (srcset) {
      dataSrcAttr['data-srcset'] = srcset
    }
  } else {
    dataSrcAttr.src = src
  }

  const dataSrcSetAttr: { 'data-sizes'?: string } = {}
  if (sizes) {
    dataSrcSetAttr['data-sizes'] = sizes
  }

  return (
    <div
      className={classNames(styles.el, outerClass)}
      style={{
        paddingTop:
          aspectRatio && !fillContainer ? `${100 / aspectRatio}%` : undefined,
        ...outerStyle,
      }}
      data-fill-container={fillContainer}
    >
      <picture>
        {srcsetWebP && (
          <source
            type='image/webp'
            // media={media}
            data-srcset={srcsetWebP}
            sizes={sizes}
          />
        )}
        <img
          alt={alt}
          className={classNames(styles.el__img, lazyLoadClass, imgClass)}
          data-fill-container={!!aspectRatio}
          style={{ objectFit, objectPosition, ...objectFitPolyfill, ...imgStyle }}
          {...dataSrcSetAttr}
          {...dataSrcAttr}
        />
      </picture>
      {placeholder && (
        <img src={placeholder} alt='' className={styles.el__placeholder} />
      )}
    </div>
  )
}

ResponsiveImage.defaultProps = {
  lazyLoad: true,
  objectFit: 'cover',
  objectPosition: 'center',
  fillContainer: false,
}


export default ResponsiveImage
