import React from 'react'
import PropTypes from 'prop-types'
import Icon from './images/whatsapp-green.inline.svg'
import styles from './whatsapp.module.scss'

const WhatsApp = ({ phone }) => {
    const handleIconClick = (e) => {
        e.preventDefault()

        const url = new URL(`https://wa.me/${phone}`);
        window.open(url, '_blank', 'scrollbars=0, resizable=1, menubar=0, left=100, top=100, width=550, height=560, toolbar=0, status=0');
    }
    return (
        <span className={styles.el}><Icon onClick={handleIconClick} /></span>
    )
}

WhatsApp.propTypes = {
    phone: PropTypes.string.isRequired,
}

export default WhatsApp
