import React, { ReactNode } from 'react'
import { useSmoothScroll } from '../../hooks/use-smooth-scroll'

const ScrollLink = ({ className, children, to = 0 }: {
  className: string,
  children?: ReactNode,
  to?: number,
}) => {
  const isClient = typeof window === 'object'

  useSmoothScroll();

  const handleClick = () => {
    if (isClient) {
      window.scroll({ top: to, behavior: 'smooth' })
    }
  }

  return (
    <button type='button' className={className} onClick={handleClick}>
      {children}
    </button>
  )
}

export default ScrollLink
