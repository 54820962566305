import React, { ReactNode, FunctionComponent } from 'react'

import BigImageSection from '../../sections/big-image'
// import BlogPostsCarouselSection from '../../sections/blog-posts-carousel'
// import BlogPostsGridSection from '../../sections/blog-posts-grid'
import BodyTextSection from '../../sections/body-text'
// import ContactSection from '../../sections/contact'
// import FeaturedProjectsSection from '../../sections/featured-projects'
import HeroSection from '../../sections/hero'
import IntroSection from '../../sections/intro'
import LogosSection from '../../sections/logos'
// import MultiImageLayoutSection from '../../sections/multi-image-layout'
// import ProfilesGridSection from '../../sections/profiles-grid'
// import TestimonialsSection from '../../sections/testimonials'
// import TextAccordionSection from '../../sections/text-accordion'
import TextAndImageSection from '../../sections/text-and-image'

interface Props {
  sections: Array<object & Basiis.FlexibleSection>
}

const SectionContainer = (
  { sections }: Props
) => {

  const sectionTypes: Record<Basiis.FlexibleSectionType, FunctionComponent<any>> = {
    'big_image': BigImageSection,
    // blog_posts_carousel: BlogPostsCarouselSection,
    // blog_posts_grid: BlogPostsGridSection,
    'body_text': BodyTextSection,
    // contact: ContactSection,
    // featured_projects: FeaturedProjectsSection,
    hero: HeroSection,
    intro: IntroSection,
    logos: LogosSection,
    // multi_image_layout: MultiImageLayoutSection,
    // profiles: ProfilesGridSection,
    // testimonials: TestimonialsSection,
    // text_accordion: TextAccordionSection,
    'text_and_image': TextAndImageSection,
  }

  const output: Array<ReactNode> = []

  sections.forEach((section, index: number) => {
    const { type } = section
    const SectionType = sectionTypes[type]
    if (!SectionType) {
      throw Error(`Couldn't find flexible module "${type}" in ${JSON.stringify(section)}`)
    }

    let nextSection = 'none'
    if (sections[index + 1]) {
      nextSection = sections[index + 1].type
    }

    let prevSection = 'none'
    if (sections[index - 1]) {
      prevSection = sections[index - 1].type
    }

    output.push(
      <SectionType
        key={section.id}
        prev={prevSection}
        next={nextSection}
        scrollEnabled={index !== 0}
        {...section}
      />
    )

    prevSection = type
  })

  return <>{output}</>
}

export default SectionContainer
