import slugify from 'slugify'
import { getResponsiveImage, IMAGE_TYPE_FIXED, IMAGE_TYPE_FLUID, IMAGE_TYPE_FLUID_MOBILE } from './get-responsive-image'
import { Props as HeroProps } from '../../sections/hero/hero'
import { Props as HeroSlideProps } from '../../sections/hero/hero-slide'
import { Props as TextAndImageProps } from '../../sections/text-and-image/text-and-image'
import { Props as IntroProps } from '../../sections/intro/intro'
import { Props as BodyTextProps } from '../../sections/body-text/body-text'
import { LinkTarget } from '../../components/link/link'
import { head } from 'lodash-es'


type SectionProps = HeroProps | TextAndImageProps | IntroProps | BodyTextProps

export const sectionSerializer = (data: GatsbyTypes.FlexibleByUidQuery, location?: Location) => {

  const sections: Array<SectionProps & Basiis.FlexibleSection> = []
  const querySections = data.prismicFlexible?.data?.body
  querySections?.forEach((s, sIndex) => {
    if (!s) {
      return
    }
    // section object
    // const section = {
    //   type: s.slice_type,
    //   id: s.id,
    // }

    // general -----------------------

    // // move 'primary' level to root
    // if (s.primary) {
    //   Object.keys(s.primary).forEach(key => {
    //     if (Object.prototype.hasOwnProperty.call(s.primary, key)) {
    //       s[key] = s.primary[key]
    //     }
    //   })
    //   delete s.primary
    // }

    // big image ---------------------

    // if (s.slice_type === 'big_image') {
    // const bi = s as GatsbyTypes.PrismicFlexibleBodyBigImage
    // section.images = []

    // s.items.forEach((item, index) => {
    //   const image = getResponsiveImage(item.image)
    //   if (image.srcset) {
    //     section.images.push({
    //       id: `${sIndex}-big-image-${index}`,
    //       image,
    //       credit: get(item, 'credit'),
    //     })
    //   }
    //   return {}
    // })
    // }

    // body text ---------------------

    if (s.slice_type === 'body_text') {
      const prismicBody = s as unknown as GatsbyTypes.PrismicFlexibleBodyBodyText
      const { primary } = prismicBody

      if (primary?.content) {
        const bodyText: BodyTextProps & Basiis.FlexibleSection = {
          id: s.id,
          type: s.slice_type,
          textContent: primary.content as Basiis.RichText,
        }
        sections.push(bodyText)
      }
    }

    //   // contact -----------------------

    //   if (s.slice_type === 'contact') {
    //     section.heading = get(s, 'heading')
    //     section.location = location
    //     section.submitLabel = get(s, 'submit_label')
    //     section.confirmationText = get(s, 'confirmation')
    //     section.formInputs = []

    //     s.items.forEach((field, index) => {
    //       section.formInputs.push({
    //         id: `${sIndex}-contact-${index}`,
    //         label: field.label,
    //         name: slugify(field.label, { lower: true }),
    //         type: field.type,
    //         required: field.required === 'true',
    //         options: field.options
    //           ? field.options.split(',').map(item => item.trim())
    //           : [],
    //       })
    //     })
    //   }

    // hero --------------------------
    if (s.slice_type === 'hero') {
      const prismicHero = s as unknown as GatsbyTypes.PrismicFlexibleBodyHero
      // section.style = s.style ? slugify(s.style, { lower: true }) : undefined
      const hero: HeroProps & Basiis.FlexibleSection = {
        id: s.id,
        type: s.slice_type,
        scrollPrompt: false,
        slides: []
      }

      prismicHero.items?.length && prismicHero.items.forEach((item, index) => {
        if (item && item.image) {
          const image = getResponsiveImage(item.image)
          if (image && image.srcset) {
            // Need to cast here because we alias the field during the query from heading to richHeading
            const heading = (item as GatsbyTypes.PrismicFlexibleBodyHeroItemType & { richHeading: GatsbyTypes.PrismicStructuredTextType }).richHeading
            const slide: HeroSlideProps = {
              id: `${sIndex}-hero-${index}`,
              heading: heading.raw && {
                html: heading.html,
                raw: heading.raw,
                text: heading.text,
              },
              description: item.description as Basiis.RichText,
              image,
              imageMobile:
                getResponsiveImage(item.image, IMAGE_TYPE_FLUID_MOBILE),
              // imageAlign: get(item, 'image_align')
              //   ? item.image_align.toLowerCase()
              //   : 'default',
            }

            if (item.cta && item.cta.url) {
              slide.cta = {
                label: item.cta_label,
                url: item.cta.url,
                target: item.cta?.target as LinkTarget,
              }
            }
            hero.slides.push(slide)
          }
        }
      })
      sections.push(hero)
    }

    // intro -------------------------

    if (s.slice_type === 'intro') {
      const flexiblePrismic = s as unknown as GatsbyTypes.PrismicFlexibleBodyIntro
      const { primary } = flexiblePrismic
      if (!primary || !primary.heading || !primary.description) {
        return
      }
      const intro: IntroProps & Basiis.FlexibleSection = {
        id: s.id,
        type: s.slice_type,
        heading: primary.heading,
        description: primary.description as Basiis.RichText,
        cta: (primary.cta_label && primary.link && primary.link.url) ? {
          title: primary.cta_label,
          // ...primary.link
          url: primary.link.url,
          target: primary.link.target,
          type: primary.link.link_type,
          lang: primary.link.lang
        } : undefined
      }
      sections.push(intro)
    }

    //   // logos -------------------------

    //   if (s.slice_type === 'logos') {
    //     section.heading = get(s, 'heading')
    //     section.items = []
    //     s.items.forEach((item, index) => {
    //       section.items.push({
    //         id: `${sIndex}-logos-${index}`,
    //         logo: item.logo,
    //         title: item.title,
    //         link: item.link,
    //       })
    //     })
    //   }

    //   // multi-image layout ------------

    //   if (s.slice_type === 'multi_image_layout') {
    //     section.background = s.background
    //       ? s.background.replace(/\s+/g, '-').toLowerCase()
    //       : undefined

    //     section.align = s.align
    //       ? s.align.replace(/\s+/g, '-').toLowerCase()
    //       : undefined

    //     section.images = []
    //     s.items.forEach((item, index) => {
    //       const image = getResponsiveImage(item.image)
    //       if (image.srcset) {
    //         section.images.push({
    //           id: `${sIndex}-multi-image-layout-${index}`,
    //           align: item.valign ? item.valign.toLowerCase() : 'top',
    //           credit: get(item, 'credit'),
    //           image,
    //         })
    //       }
    //     })
    //   }

    //   // testimonials ------------------

    //   if (s.slice_type === 'testimonials') {
    //     section.heading = s.heading

    //     section.items = []
    //     s.items.forEach((item, index) => {
    //       section.items.push({
    //         id: `${sIndex}-testimonials-${index}`,
    //         quote: get(item, 'quote'),
    //         video: item.video,
    //         image: getResponsiveImage(item.image),
    //         source: get(item, 'source'),
    //         sourceImage: getResponsiveImage(item.source_image),
    //       })
    //     })
    //   }

    //   // text accordion ----------------

    //   if (s.slice_type === 'text_accordion') {
    //     section.heading = get(s, 'heading')
    //     section.bodyText = get(s, 'body_text')

    //     section.items = []
    //     s.items.forEach((item, index) => {
    //       section.items.push({
    //         id: `${sIndex}-text-accordion-${index}`,
    //         heading: get(item, 'heading'),
    //         content: get(item, 'content'),
    //       })
    //     })
    //   }

    // text and image ----------------

    if (s.slice_type === 'text_and_image') {
      const flexiblePrismic = s as unknown as GatsbyTypes.PrismicFlexibleBodyTextAndImage
      const { primary } = flexiblePrismic
      // section.style = s.style ? slugify(s.style, { lower: true }) : undefined
      // const hero: HeroProps & FlexibleSection = { id: s.id, type: s.slice_type, scrollPrompt: false, slides: [] 
      if (!primary?.body_text) {
        return
      }

      const textAndImage: TextAndImageProps & Basiis.FlexibleSection = {
        id: s.id,
        type: s.slice_type,
        heading: primary?.heading,
        bodyText: primary.body_text as Basiis.RichText,
        fullColumnImage: primary?.full_column_image,
        layout: primary?.layout,
        images: [],
        icons: [],
      }

      if (primary?.cta_label && primary.link?.url && primary.link.link_type) {
        textAndImage.cta = {
          title: primary.cta_label,
          url: primary.link.url,
          target: primary.link.target,
          type: primary.link.link_type,
          lang: primary.link.lang
        }
      }
      if (primary.video?.url) {
        textAndImage.video = {
          url: primary.video.url,
        }
      }

      flexiblePrismic.items?.forEach((item) => {
        if (!item?.image) {
          return
        }

        const image = getResponsiveImage(item.image, IMAGE_TYPE_FLUID)
        if (image) {
          textAndImage.images.push(image)
        }

        if (item.image.thumbnails && item.image.thumbnails.swatch) {
          const icon = getResponsiveImage(item.image.thumbnails.swatch, IMAGE_TYPE_FIXED)
          textAndImage.icons.push(icon)
        }
      })
      sections.push(textAndImage)
    }
  })

  return sections
}
