import React from 'react'
import useDimensions from 'react-use-dimensions'

import styles from './hero.module.scss'
import HeroSlide, { Props as HeroSlideProps } from './hero-slide'
import { CarouselItem } from '../../components/carousel'
import Section from '../../components/section'
import ScrollLink from '../../components/scroll-link'
import ArrowSvg from './images/arrow-down.inline.svg'

export interface Props {
  slides: Array<HeroSlideProps>,
  scrollPrompt: boolean
}

const HeroSection = ({ slides, scrollPrompt = true }: Props) => {
  const [spacerRef, { height }] = useDimensions() // get height

  const slidesOutput = slides.map(slide => {
    return (
      <CarouselItem key={slide.id} className={styles.el__item}>
        <HeroSlide
          {...slide}
        />
      </CarouselItem>
    )
  })

  return (
    <Section
      className={styles.el}
      scrollEnabled={false}
    >
      <div className={styles.el__spacer} ref={spacerRef} />
      <div className={styles.el__container}>
        {scrollPrompt && (
          <div className={styles.el__scroll}>
            <div className={styles.el__scroll__container}>
              <ScrollLink className={styles.el__scroll__button} to={height}>
                <div className={styles.el__scroll__button__icon}>
                  <ArrowSvg />
                </div>
              </ScrollLink>
            </div>
          </div>
        )}
        {slides.length ? (
          <div className={styles.el__carousel_slide}>{slidesOutput}</div>
        ) : (
            slidesOutput
          )}
      </div>
    </Section>
  )
}

export default HeroSection