import React from 'react'

import styles from './intro.module.scss'
import Section from '../../components/section'
import Container from '../../components/container'
import ResponsiveImage from '../../components/responsive-image'
import Indent from '../../components/indent'
import Button from '../../components/button'
import RichText from '../../components/rich-text'

export interface Props {
  heading: string,
  description: Basiis.RichText,
  cta?: Basiis.Link,
  image?: Basiis.ResponsiveImage,
}

const IntroSection = ({ heading, description, cta, image }: Props) => {
  return (
    <Section className={styles.el}>
      <Container>
        {image && (image.src || image.srcset) && (
          <div className={styles.el__image}>
            <ResponsiveImage {...image} />
          </div>
        )}
        <Indent>
          <div className={styles.el__container}>
            <div className={styles.el__side} />
            <div className={styles.el__main}>
              <h3 className={styles.el__heading}>{heading}</h3>
              <div className={styles.el__description}>
                <RichText render={description.raw} />
              </div>
              {cta && cta.url && cta.title && (
                <div className={styles.el__button}>
                  <Button href={cta.url} target={cta.target}>
                    {cta.title}
                  </Button>
                </div>
              )}
            </div>
          </div>
        </Indent>
      </Container>
    </Section>
  )
}

export default IntroSection
