export const IMAGE_TYPE_FLUID = 'fluid'
export const IMAGE_TYPE_FLUID_MOBILE = 'fluid-mobile'
export const IMAGE_TYPE_FIXED = 'fixed'


export const getResponsiveImage = (data: GatsbyTypes.PrismicImageInterface, type = IMAGE_TYPE_FLUID): Basiis.ResponsiveImage => {
  // eslint-disable-next-line @typescript-eslint/camelcase
  let imageObject: GatsbyTypes.ImageSharpFluid | GatsbyTypes.GatsbyImageSharpFluid_withWebpFragment | GatsbyTypes.ImageSharpFixed | null = null

  let width = data.dimensions?.width
  let height = data.dimensions?.height

  switch (type) {
    case IMAGE_TYPE_FLUID:
      if (data.localFile?.childImageSharp?.fluid) {
        imageObject = data.localFile.childImageSharp.fluid
        break;
      }
    case IMAGE_TYPE_FLUID_MOBILE:
      if (data.localFile?.childImageSharp?.hasOwnProperty('mobile')) {
        // eslint-disable-next-line @typescript-eslint/camelcase
        imageObject = data.localFile.childImageSharp as unknown as GatsbyTypes.GatsbyImageSharpFluid_withWebpFragment
        break;
      }
    case IMAGE_TYPE_FIXED:
      if (data.localFile?.childImageSharp?.fixed) {
        width = data.localFile.childImageSharp.fixed.width
        height = data.localFile.childImageSharp.fixed.height
        imageObject = data.localFile.childImageSharp.fixed
        break;
      }
    default:
      break;
  }

  if (!width || !height) {
    throw Error(`Missing images dimensions for image ${JSON.stringify(imageObject)}`)
  }

  if (imageObject) {
    return {
      id: `${type}-${data.localFile?.id}`,
      src: imageObject.src,
      srcWebP: imageObject.srcWebp,
      srcset: type === IMAGE_TYPE_FIXED ? undefined : imageObject.srcSet,
      srcsetWebP: type === IMAGE_TYPE_FIXED ? undefined : imageObject.srcSetWebp,
      sizes: (type === IMAGE_TYPE_FLUID || type === IMAGE_TYPE_FLUID_MOBILE) ? (imageObject as GatsbyTypes.ImageSharpFluid).sizes : undefined,
      aspectRatio: imageObject.aspectRatio || width / height,
      placeholder: imageObject.base64,
      alt: data.alt,
      width,
      height,
    }
  }

  if (!data.url) {
    throw Error(`Don't know how to treat this data`)
  }

  // This is likely coming from preview
  return {
    id: data.url,
    src: data.url,
    alt: data.alt,
    width,
    height,
    aspectRatio: width / height
  }
}
