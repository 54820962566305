import React from 'react'

import styles from './flexible.module.scss'
import SectionContainer from '../../components/section-container'

interface Props {
  sections: Array<object & Basiis.FlexibleSection>,
  borderBottom: boolean,
  topPadding: boolean
}

const FlexibleTemplate = ({ sections, borderBottom = false, topPadding = true }: Props) => {
  return (
    <div className={styles.el} data-top-padding={topPadding}>
      <SectionContainer sections={sections} />
      {borderBottom && <div className={styles.el__border} />}
    </div>
  )
}

export default FlexibleTemplate
