import React from 'react'
import PropTypes from 'prop-types'
import styles from './big-image.module.scss'
import Container from '../../components/container'
import ResponsiveImage from '../../components/responsive-image'
import { Carousel, CarouselItem } from '../../components/carousel'
import Section from '../../components/section'
import ImageCredit from '../../components/image-credit'
import RichText from '../../components/rich-text'

const BigImageSection = ({ images }) => {
  const imagesOutput = images.map(item => (
    <CarouselItem className={styles.el__item} key={item.id}>
      {(item.image.srcset || item.image.src) && (
        <>
          <button
            type='button'
            onClick={item.image.onClick}
            className={styles.el__item__link}
          >
            <ResponsiveImage {...item.image} />
          </button>
          {item.credit ? (
            <ImageCredit>
              Image: <RichText render={item.credit.raw} />
            </ImageCredit>
          ) : (
            <ImageCredit>&nbsp;</ImageCredit>
          )}
        </>
      )}
    </CarouselItem>
  ))

  return (
    <Section className={styles.el}>
      <Container>
        <div className={styles.el__container}>
          {imagesOutput.length ? (
            <Carousel
              autoplay={{ delay: 6000 }}
              speed={800}
              loop
              counterClass={styles.el__counter}
            >
              {imagesOutput}
            </Carousel>
          ) : (
              <>{imagesOutput}</>
            )}
        </div>
      </Container>
    </Section>
  )
}

BigImageSection.defaultProps = {
  images: [],
}

BigImageSection.propTypes = {
  images: PropTypes.arrayOf(
    PropTypes.shape({
      credit: PropTypes.string,
      image: PropTypes.object,
    })
  ),
}

export default BigImageSection
