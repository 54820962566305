import React, { ReactNode } from 'react'
import { motion, Variants, Variant } from 'framer-motion'
import { RichText, Elements } from 'prismic-reactjs'

import styles from './hero-slide.module.scss'
import Link from '../../components/link'
import ResponsiveImage from '../../components/responsive-image'
import Indent from '../../components/indent'
import Container from '../../components/container'
import { LinkTarget } from '../../components/link/link'

const htmlSerializer = (type: string, _element: object, _content: string, children: ReactNode) => {
  if (type === Elements.paragraph) {
    // Strip out the <p> tag of the heading
    return children
  }
  return null
}

export interface Props {
  id: string,
  image: Basiis.ResponsiveImage,
  imageMobile: Basiis.ResponsiveImage,
  heading?: Basiis.RichText,
  description: Basiis.RichText,
  cta?: { url: string, label?: string, target?: LinkTarget },
  // imageAlign: 'default' | 'bottom' | 'top',
}

const HeroSlide = ({
  image,
  imageMobile,
  heading,
  description,
  cta,
  // imageAlign = 'default',
}: Props) => {

  // let imageAlignOutput = 'center'
  // if (imageAlign === 'bottom') {
  //   imageAlignOutput = 'center bottom'
  // } else if (imageAlign === 'top') {
  //   imageAlignOutput = 'center top'
  // }

  const imageParams: Basiis.ResponsiveImage = {
    ...image,
    // objectPosition: imageAlignOutput,
    // fillContainer: true,
  }

  const imageMobileParams = {
    ...imageMobile,
    // fillContainer: true,
  }

  const variants: Variants = {
    hidden: {
      '--mask-start': '-40%',
      '--mask-end': '0%'
    } as Variant,
    visible: {
      '--mask-start': '100%',
      '--mask-end': '140%'
    } as Variant
  }
  return (
    <div className={styles.el}>
      {(imageParams.srcset || imageParams.src) && (
        <div
          className={styles.el__image}
          data-desktop-only={
            imageMobileParams.srcset || imageMobileParams.src ? 'true' : 'false'
          }
        >
          <ResponsiveImage fillContainer={true} {...imageParams} />
        </div>
      )}
      {(imageMobileParams.srcset || imageMobileParams.src) && (
        <div className={styles.el__image} data-mobile-only='true'>
          <ResponsiveImage fillContainer={true} {...imageMobileParams} />
        </div>
      )}
      <Container className={styles.el__text}>
        <Indent className={styles.el__text__container}>
          <div className={styles.el__side} />
          <div className={styles.el__main}>
            {heading && (
              <motion.h1
                className={styles.el__heading}
                role='heading'
                variants={variants}
                initial={['hidden']}
                animate={['visible']}
                transition={{ duration: 1.5, delay: 1.1 }}
              >
                <RichText
                  render={heading.raw}
                  htmlSerializer={htmlSerializer}
                />
              </motion.h1>
            )}
            {description.text && (
              <div className={styles.el__description}>
                <RichText render={description.raw} />
              </div>
            )}
            {cta && cta.url && (
              <div className={styles.el__links}>
                <Link to={cta.url} target={cta.target}>
                  {cta.label || 'Read More'}
                </Link>
              </div>
            )}
          </div>
        </Indent>
      </Container>
    </div >
  )
}

export default HeroSlide
