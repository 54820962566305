import { useEffect, useState } from 'react'

const IS_BROWSER = typeof window !== 'undefined'
/**
 * Prismic preview hook
 * @param {object} staticData Data object from Gatsby page
 */
export function usePreview(staticData: object) {
    const [data, setData] = useState(staticData);

    useEffect(() => {
        const previewData = IS_BROWSER && window.__PRISMIC_PREVIEW_DATA__;
        const prepareData = async () => {
            if (previewData) {
                const { mergePrismicPreviewData } = await import(
                /* webpackChunkName: "gatsby-source-prismic" */ 'gatsby-source-prismic'
                );

                setData(
                    mergePrismicPreviewData({
                        staticData,
                        previewData
                    }) as object
                );
            }
        }
        if (previewData) {
            prepareData();
        }
    }, [staticData]);

    return data;
}