import React, { ReactNode, ReactType } from 'react'
import classNames from 'classnames'
import { Link } from 'gatsby'

import styles from './button.module.scss'
import ArrowIconSVG from './images/arrow.inline.svg'

interface Props {
  link: Basiis.Link,
  href: string,
  target: string,
  children: ReactNode,
  Tag: ReactType,
  type: string,
  className: string,
}

const Button = (props: Props) => {
  const { link, href, target, children, Tag, type, className } = props
  const classes = classNames(styles.el, className)

  const content = (
    <span className={styles.el__inner}>
      <span className={styles.el__icon}>
        <ArrowIconSVG />
      </span>
      <span className={styles.el__text}>{children}</span>
    </span>
  )
  if (link) {
    return (
      <Link className={classes} to={link.url}>
        {content}
      </Link>
    )
  }
  return (
    <Tag href={href} className={classes} type={type} target={target}>
      {content}
    </Tag>
  )
}

Button.defaultProps = {
  link: null,
  href: '',
  target: '',
  Tag: 'a',
  type: '', // for <button type="" />
  className: '',
}

export default Button
