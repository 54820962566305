import React, { useCallback, useEffect } from 'react'
import { motion } from 'framer-motion'
import PropTypes from 'prop-types'
import styles from './lightbox.module.scss'

const Lightbox = ({ children, isOpen, onClose, containerPadding }) => {
  const duration = 0.75

  const variants = {
    hidden: {
      y: '100%',
      transition: { duration, ease: 'backInOut' },
    },
    visible: {
      y: '0%',
      transition: {
        delay: 0.2,
        duration,
        ease: 'backInOut',
      },
    },
  }

  const bgVariations = {
    hidden: {
      scaleY: 0,
      transition: {
        delay: 0.2,
        duration,
        ease: 'backInOut',
      },
    },
    visible: {
      scaleY: 1,
      transition: { duration, ease: 'backInOut' },
    },
  }

  const handleKeyPress = useCallback(event => {
    // esc key
    if (event.keyCode === 27) {
      onClose()
    }
  }, [])

  useEffect(() => {
    document.addEventListener('keydown', handleKeyPress, false)

    return () => {
      document.removeEventListener('keydown', handleKeyPress, false)
    }
  }, [])

  return (
    <div className={styles.el} data-is-open={isOpen}>
      <motion.div
        className={styles.el__bg}
        variants={bgVariations}
        animate={isOpen ? 'visible' : 'hidden'}
      />
      <div className={styles.el__close}>
        <motion.div
          className={styles.el__close__inner}
          variants={variants}
          animate={isOpen ? 'visible' : 'hidden'}
        >
          <button
            type="button"
            onClick={onClose}
            className={styles.el__close__btn}
          >
            Close
          </button>
        </motion.div>
      </div>
      <motion.div
        className={styles.el__container}
        variants={variants}
        animate={isOpen ? 'visible' : 'hidden'}
        data-padding={containerPadding}
      >
        {children}
      </motion.div>
    </div>
  )
}

Lightbox.defaultProps = {
  children: undefined,
  isOpen: false,
  onClose: () => undefined,
  containerPadding: true,
}

Lightbox.propTypes = {
  children: PropTypes.node,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  containerPadding: PropTypes.bool,
}

export default Lightbox
