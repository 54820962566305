import React, { useEffect, useState, ReactNode } from 'react'
import { Waypoint } from 'react-waypoint'
import classNames from 'classnames'
import styles from './section.module.scss'

export interface SectionNavigation {
  scrollEnabled: boolean,
  prev: string,
  next: string,
}

interface Props {
  children: ReactNode,
  className: string,
  topPadding?: boolean,
  bottomPadding?: boolean,
  transitionIn?: (args?: Waypoint.CallbackArgs) => void,
  transitionDelay?: number,
}

const Section = ({
  children,
  className,
  prev,
  next,
  topPadding,
  bottomPadding,
  scrollEnabled,
  transitionIn,
  transitionDelay,
}: Props & SectionNavigation) => {
  const classes = classNames(styles.el, className)
  const [isTransitioned, setIsTransitioned] = useState(false)

  const handleTransitionIn = () => {
    if (transitionIn) {
      transitionIn()
    }
    setIsTransitioned(true)
  }

  useEffect(() => {
    if (!scrollEnabled) {
      const timer = setTimeout(() => {
        handleTransitionIn()
      }, transitionDelay)
      return () => clearTimeout(timer)
    }
    return undefined
  }, [scrollEnabled, transitionIn])

  const section = (
    <section
      className={classes}
      data-top-padding={topPadding}
      data-bottom-padding={bottomPadding}
      data-next={next}
      data-prev={prev}
      data-transition-in={isTransitioned}
    >
      {children}
    </section>
  )

  if (scrollEnabled) {
    return (
      <Waypoint
        onEnter={transitionIn}
        scrollableAncestor={typeof window === 'object' ? window : undefined}
      >
        {section}
      </Waypoint>
    )
  }

  return <>{section}</>
}

Section.defaultProps = {
  prev: 'none',
  next: 'none',
  topPadding: true,
  bottomPadding: true,
  scrollEnabled: false,
  transitionDelay: 750,
}
export default Section
