import React, { useState } from 'react'
import classNames from 'classnames'

import styles from './text-and-image.module.scss'
import Section from '../../components/section'
import Container from '../../components/container'
import RichText from '../../components/rich-text'
import Button from '../../components/button'
import ResponsiveImage from '../../components/responsive-image'
import VideoPoster from '../../components/video-poster'
import LightboxVideo from '../../components/lightbox-video'
import Indent from '../../components/indent'
import { SectionNavigation } from '../../components/section/section'

interface Video {
  url: string
}


interface MediaProps {
  images: Array<Basiis.ResponsiveImage>,
  setOpen: Function,
  fullColumn: boolean,
  video?: Video,
}

const Media = ({ images, video, setOpen, fullColumn = false }: MediaProps) => {
  let className = null
  if (!fullColumn) {
    className = styles.el__image_outer_icon_width_image
  }

  return (
    <div className={classNames(styles.el__image_outer, className)}>
      {images.map(image => {
        return (
          <ResponsiveImage
            {...image}
            key={image.id}
            outerClass={styles.el__image}
          />
        )
      })}
      {video && video.url && (
        <VideoPoster
          onClick={() => {
            setOpen(true)
          }}
          // placeholder=''
          fillContainer
        />
      )}
    </div >
  )
}

Media.defaultProps = {
  images: [],
  fullColumn: false
}


export interface Props {
  heading?: string,
  bodyText: Basiis.RichText,
  fullColumnImage?: boolean,
  images: Array<Basiis.ResponsiveImage>,
  icons: Array<Basiis.ResponsiveImage>
  cta?: Basiis.Link,
  layout?: 'image-first' | 'text-first',
  video?: Video,
}

const TextAndImageSection = ({
  heading,
  bodyText,
  images,
  icons,
  cta,
  layout,
  video,
  fullColumnImage,
  next,
  prev
}: Props & SectionNavigation) => {
  const [open, setOpen] = useState(false)
  return (
    <>
      {video && video.url && (
        <LightboxVideo
          videoURL={video.url}
          isOpen={open}
          onClose={() => {
            setOpen(false)
          }}
        />
      )}
      <Section className={styles.el} topPadding={false} bottomPadding={false} prev={prev} next={next}>
        <Container>
          <Indent className={styles.el__container} order={layout === 'text-first' ? 1 : 2}>
            <div
              className={classNames(styles.el__side, styles.el__side_empty, fullColumnImage ? styles.el__side_full_column_image : styles.el__side_empty)}
            >
              {/* empty left block */}
            </div>
            <div
              className={styles.el__main}
            >
              <div className={styles.el__text_outer}>
                {heading && <h3 className={classNames(prev === 'hero' ? styles.el__heading_title : styles.el__heading)}>{heading}</h3>}
              </div>
            </div>
          </Indent>
          <Indent className={styles.el__container} order={layout === 'text-first' ? 1 : 2}>
            <div
              className={classNames(styles.el__side, fullColumnImage ? styles.el__side_full_column_image : styles.el__side_empty)}
            >
              {fullColumnImage && <Media images={images} video={video} setOpen={setOpen} fullColumn />}
            </div>
            <div
              className={styles.el__main}
            >
              {!fullColumnImage && <Media images={icons} video={video} setOpen={setOpen} fullColumn={false} />}
              <div className={styles.el__text_outer}>
                <div className={styles.el__body_text}>
                  <RichText render={bodyText.raw} />
                </div>
                {cta && cta.url && (
                  <div className={styles.el__button}>
                    <Button link={cta}>{cta.title}</Button>
                  </div>
                )}
              </div>
            </div>
          </Indent>
        </Container>
      </Section>
    </>
  )
}

TextAndImageSection.defaultProps = {
  fullColumnImage: false,
  images: []
}

export default TextAndImageSection
