import { useEffect } from 'react'

/**
 * Prismic preview hook
 * @param {object} staticData Data object from Gatsby page
 */
export function useSmoothScroll() {
    useEffect(() => {
        const polyfill = async () => {
            const smoothscroll = await import(
                /* webpackChunkName: "smoothscroll-polyfill" */ 'smoothscroll-polyfill'
            );

            smoothscroll.polyfill();
        }

        const IS_BROWSER = typeof window !== 'undefined'

        // Test logic taken from polyfill
        // https://github.com/iamdustan/smoothscroll/blob/dbae5bd33ecf1fa4f0eaedf7df2a864a16339f5d/src/smoothscroll.js#L10
        const NEED_POLYFILL = !('scrollBehavior' in document.documentElement.style) || window.__forceSmoothScrollPolyfill__ === true
        if (IS_BROWSER && NEED_POLYFILL) {
            polyfill();
        }
    }, []);
}