import React, { ReactNode } from 'react'
import { RichText, Elements } from 'prismic-reactjs'

import styles from './body-text.module.scss'
import Container from '../../components/container'
import Section from '../../components/section'
import Indent from '../../components/indent'
import { linkResolver } from '../../prismic/utils/link-resolver'
import { withHtmlSerializerObfuscator } from '../../utils/html-serializer'
import WhatsApp from '../../components/whatsapp'

const htmlSerializer = (type: string, element: any, content: string, children: ReactNode, key: string) => {
  if (type === Elements.label) {
    switch (element.data.label) {
      case 'map':
        return (
          <iframe
            key={key}
            title={`${element.data.label}-${key}`}
            width='100%'
            height='450'
            frameBorder='0'
            className={styles.el__map}
            src={content}
            allowFullScreen
          />
        )
      case 'whatsapp':
        return (<WhatsApp key={key} phone={content.replace(/\W/g, '')} />)
      default:
        break;
    }
  }
  return null
}

export interface Props {
  textContent: Basiis.RichText
}

const BodyTextSection = ({ textContent }: Props) => {
  return (
    <Section className={styles.el}>
      <Container>
        <Indent className={styles.el__container}>
          <div className={styles.el__side} />
          <div className={styles.el__main}>
            <div className={styles.el__text_content}>
              <RichText
                render={textContent.raw}
                linkResolver={linkResolver}
                htmlSerializer={withHtmlSerializerObfuscator(htmlSerializer)}
              />
            </div>
          </div>
        </Indent>
      </Container>
    </Section>
  )
}

export default BodyTextSection
