import React from 'react'
import { graphql } from 'gatsby'

import FlexibleTemplate from '../../templates/flexible'
import { sectionSerializer } from '../utils/section-serializer'
import { usePreview } from '../utils/use-prismic-preview'

const FlexiblePrismic = ({ data: queryData, location }: { data: GatsbyTypes.FlexibleByUidQuery, location: Location }) => {
  const d = usePreview(queryData) as GatsbyTypes.FlexibleByUidQuery
  const sections = sectionSerializer(d, location)
  const borderBottom = d.prismicFlexible?.data?.newsletter_signup === 'true'
  const topPadding = d.prismicFlexible?.data?.body?.length ? d.prismicFlexible.data.body[0]?.slice_type === 'hero' : false
  return (
    <FlexibleTemplate
      sections={sections}
      borderBottom={borderBottom}
      topPadding={topPadding}
    />
  )
}

export default FlexiblePrismic

export const pageQuery = graphql`
  query FlexibleByUid($uid: String!, $lang: String!) {
    prismicFlexible(uid: { eq: $uid }, lang: { eq: $lang }) {
      uid
      lang
      type
      alternate_languages {
        id
        lang
        type
        uid
      }
      data {
        title
        newsletter_signup
        meta_title
        meta_description
        canonical_url
        no_index
        card_description
        card_image {
          localFile {
            childImageSharp {
              fixed(width: 1200, height: 630) {
                ...GatsbyImageSharpFixed
              }
            }
          }
        }
        card_image_square {
          localFile {
            childImageSharp {
              fixed(width: 512, height: 512) {
                ...GatsbyImageSharpFixed
              }
            }
          }
        }
        card_title
        body {
          ... on PrismicFlexibleBodyHero {
            id
            slice_type
            items {
              richHeading: heading {
                html
                raw
                text
              }
              description {
                html
                raw
                text
              }
              cta_label
              cta {
                url
                target
              }
              image {
                localFile {
                  id
                  uid
                  childImageSharp {
                    fluid(quality: 90, toFormat: JPG, maxWidth: 1920, srcSetBreakpoints: [200, 400, 800, 1200, 1600, 1920]) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                    mobile: fluid(maxWidth: 480, maxHeight: 600, quality: 90, toFormat: JPG) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
                url
                alt
                dimensions {
                  width
                  height
                }
              }
            }
          }
          ... on PrismicFlexibleBodyBigImage {
            id
            slice_type
            items {
              image {
                localFile {
                  id
                  uid
                  childImageSharp {
                    fluid(maxWidth: 2000, quality: 85) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
                url
                alt
                dimensions {
                  width
                  height
                }
              }
              credit {
                html
                raw
              }
            }
          }
          ... on PrismicFlexibleBodyBodyText {
            id
            slice_type
            primary {
              content {
                raw
                html
                text
              }
            }
          }
          ... on PrismicFlexibleBodyContact {
            id
            slice_type
            primary {
              heading
              submit_label
              confirmation {
                html
              }
            }
            items {
              label
              type
              required
              options
            }
          }
          ... on PrismicFlexibleBodyLogos {
            id
            slice_type
            primary {
              heading
            }
            items {
              logo {
                url
              }
              title
              link {
                url
                link_type
                target
              }
            }
          }
          ... on PrismicFlexibleBodyTestimonials {
            id
            slice_type
            primary {
              heading
            }
            items {
              quote {
                html
                raw
              }
              video {
                url
              }
              image {
                localFile {
                  id
                  uid
                  childImageSharp {
                    fluid(maxWidth: 2000, quality: 85) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
                url
                alt
                dimensions {
                  width
                  height
                }
              }
              source {
                html
                raw
              }
              source_image {
                localFile {
                  id
                  uid
                  childImageSharp {
                    fluid(maxWidth: 2000, quality: 85) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
                url
                alt
                dimensions {
                  width
                  height
                }
              }
            }
          }
          ... on PrismicFlexibleBodyTextAccordion {
            id
            slice_type
            primary {
              heading
              body_text {
                html
                raw
              }
            }
            items {
              heading
              content {
                html
                raw
              }
            }
          }
          ... on PrismicFlexibleBodyTextAndImage {
            id
            slice_type
            primary {
              heading
              body_text {
                html
                raw
              }
              cta_label
              link {
                url
                target
                link_type
              }
              layout
              full_column_image
              video {
                url
              }
            }
            items {
              image {
                localFile {
                  id
                  uid
                  childImageSharp {
                    fluid(maxWidth: 480, quality: 95, toFormat: JPG) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                    fixed(height: 120, width: 120, quality: 95, toFormat: JPG) {
                      ...GatsbyImageSharpFixed_withWebp
                    }
                  }
                }
                thumbnails {
                  swatch {
                    localFile {
                      id
                      uid
                      childImageSharp {
                        fluid(maxWidth: 240, quality: 95, toFormat: JPG) {
                          ...GatsbyImageSharpFluid_withWebp
                        }
                        fixed(
                          height: 120
                          width: 120
                          quality: 95
                          toFormat: JPG
                        ) {
                          ...GatsbyImageSharpFixed_withWebp
                        }
                      }
                    }
                  }
                }
                url
                alt
                dimensions {
                  width
                  height
                }
              }
            }
          }
          ... on PrismicFlexibleBodyIntro {
            id
            slice_type
            primary {
              heading
              description {
                html
                raw
              }
              cta_label
              link {
                url
                link_type
                target
              }
            }
          }
        }
      }
    }
  }
`